import React, { useEffect, useState, useRef, useMemo } from 'react'
import styles from './style.module.scss'
import cn from 'clsx'
import images from 'assets/images'
import { chainType } from 'common/constants'
import { useTranslation } from 'react-i18next'
import SocialNetworkLink from 'components/SocialNetworkLink'
import InputSearch from 'components/InputSearch'
import PageDropdown from 'components/PageDropdown'
import CustomTable from 'components/CustomTable'
import moment from 'moment'
import {
  formatNumberBro,
  formatPrice,
  getLength,
  upperCase,
  validateBlockChainAddress
} from 'common/functions'
import Pagination from 'components/Pagination'
import get from 'lodash/get'
import { Icon } from 'components/Icon'
import { useSelector } from 'react-redux'
import TokenImage from 'components/common/TokenImage'
import ReduxServices from 'common/redux'
import BannerImage from 'components/common/BannerImage'
import Tooltip from 'components/Tooltip'
import ShareModal from 'pages/NewFarmScreen/Components/Modals/ShareModal'
import BaseAdapter from 'controller/api/BaseAdapter'
import Badge from 'components/Badge'

const ARR_SORT = [
  {
    label: 'Last week',
    value: 'last7Days'
  },
  {
    label: 'Last 2 weeks',
    value: 'last14Days'
  },
  {
    label: 'Last month',
    value: 'last30Days'
  },
  {
    label: 'All Time',
    value: 'all'
  }
]

const dataHead = [
  { filed: 'rank', type: 'number' },
  { filed: 'address', type: 'address' },
  { filed: 'tradingVolume', type: 'number' },
  { filed: 'farmStakeVolume', type: 'number' },
  { filed: 'point', type: 'number' }
]

const size = 50

const LeaderBoard = () => {
  const { t } = useTranslation()
  const timer = useRef()
  const addressWalletActive = useSelector((state) => state.accountSol)

  const [txtSearch, setTxtSearch] = useState('')
  const [sort, setSort] = useState('all')
  const [campDetail, setCampDetail] = useState({})
  const [isLoading, setIsLoading] = useState(true)

  const [searchDataJoin, setSearchDataJoin] = useState([])
  const [total, setTotal] = useState()
  const [currPage, setCurrPage] = useState(1)
  const langRedux = useSelector((state) => state.langRedux)

  const totalPageRef = useRef(1)
  const defaultDataJoin = useRef([])

  const isMobile = window.innerWidth < 576

  const tokenSymbol = get(campDetail, 'tokenInfo.symbol', '')

  const itemDropdown = ARR_SORT.find(item => item.value === sort)

  useEffect(() => {
    loadingCampaignDetail()
  }, [addressWalletActive])

  useEffect(() => {
    loadingAddressList()
  }, [currPage, sort, addressWalletActive])

  const loadingCampaignDetail = async () => {
    const res = await BaseAdapter.getData('saros/leader/data')

    if (res?.data) {
      setCampDetail(res?.data)
    }
  }

  const loadingAddressList = async () => {
    setIsLoading(true)

    const body = {
      page: currPage,
      size: size,
      // sort: sort,
      // keyword: txtSearch,
      // address: addressWalletActive,
      address: txtSearch,
      type: sort
    }

    const isValidAddress = validateBlockChainAddress(
      txtSearch,
      chainType.solana
    )

    if (getLength(txtSearch) > 0 && !isValidAddress) {
      setIsLoading(false)
      setSearchDataJoin([])
      return
    }

    const response = await BaseAdapter.getData('saros/leader/list', body)

    const resJoinData = response?.data

    if (resJoinData) {
      if (getLength(txtSearch) === 0) {
        defaultDataJoin.current = get(resJoinData, 'data')
      }
      setSearchDataJoin(get(resJoinData, 'data'))
      // setDataHead(get(resJoinData, 'render'))
      const totalPage = Math.ceil(resJoinData.total / size)
      setTotal(totalPage)
      totalPageRef.current = totalPage
      setIsLoading(false)
    }
  }

  const handleSearchSnapshotDetail = (e) => {
    setIsLoading(true)
    const { value } = e.target

    clearTimeout(timer.current)
    if (getLength(value) === 0) {
      setIsLoading(false)
      setSearchDataJoin(defaultDataJoin.current)
      setTotal(totalPageRef.current)
      setTxtSearch('')
    } else if (getLength(value) > 0) {
      setTxtSearch(value)
      const isValidAddress = validateBlockChainAddress(value, chainType.solana)
      if (!isValidAddress) {
        setIsLoading(false)
        setSearchDataJoin([])
        return
      }
      const body = {
        page: 1,
        size: size,
        // id: id,
        // sort: sort,
        // keyword: value
        address: value,
        type: sort
      }
      timer.current = setTimeout(async () => {
        const response = await BaseAdapter.getData('saros/leader/list', body)
        const data = response?.data
        if (data) {
          setSearchDataJoin(data.data)
          setIsLoading(false)
          const totalPage = Math.ceil(data.total / size)
          setTotal(totalPage)
        } else {
          setIsLoading(false)
        }
      }, 500)
    }
  }

  const handleClearSearch = () => {
    setTxtSearch('')
    setSearchDataJoin(defaultDataJoin.current)
    setTotal(totalPageRef.current)
  }

  const onChangeSort = (sort) => {
    setSort(sort.value)
  }

  const onChangePage = (val) => {
    const { selected } = val
    setCurrPage(selected + 1)
  }

  const onZoomImage = () => {
    window.openModal({
      isCustomModal: true,
      content: (
        <div className={styles.modalImage}>
          <img src={campImage} alt="" />
        </div>
      ),
      onCancel: () => {}
    })
  }

  const onShowShareModal = () => {
    window.openModal({
      isCustomModal: true,
      className: styles.listTokenModal,
      isFullHeight: true,
      content: <ShareModal type={'leaderboard'} campDetail={campDetail} />,
      onCancel: () => {}
    })
  }

  const renderTableCell = (typeRender, val) => {
    if ((typeRender.type === 'number' && typeRender.filed === 'tradingVolume') || typeRender.filed === 'farmStakeVolume') {
      return `${ReduxServices.formatPrice(val)}`
    }
    if (typeRender.type === 'number' && typeRender.filed === 'reward') {
      return `${formatNumberBro({ number: val, mantissa: 2 })} ${upperCase(
        tokenSymbol
      )}`
    }
    if (typeRender.type === 'number' && typeRender.filed === 'rank') {
      if ([1, 2, 3].includes(val)) {
        const isActiveRank1 = val === 1
        return <div className='item-container'>
          <div className={isActiveRank1 ? 'item-circle' : ''}>
            <div className='item-rank'>
              {val}
            </div>
          </div>

        </div>
      }
      return val > 0 ? val : '-'
    }
    if (typeRender.type === 'boolean') {
      return (
        <div className="d-flex justify-content-center">
          <Icon
            name="web_ic-verify-un"
            className={cn('text-l', val ? 'color-theme' : 'color-grey')}
          />
        </div>
      )
    }

    if (typeRender.type === 'date') {
      return moment(val).format('DD/MM HH:mm')
    }

    return val
  }

  const tableHead = useMemo(() => {
    if (getLength(dataHead) === 0) return []
    return dataHead.map((item, index) => ({
      title: t(item.filed),
      dataIndex: item.filed,
      align:
        index === 0 || item.type === 'boolean'
          ? 'center'
          : index > 1
            ? 'right'
            : '',
      width: index === 0 ? 5 : 15,
      render: (val) => {
        const cell = renderTableCell(item, val)
        return cell
      }
    }))
  }, [dataHead, langRedux])

  const tableData = useMemo(() => {
    if (getLength(searchDataJoin) === 0) return []
    return searchDataJoin.map(item => {
      if (item?.rank === 1) {
        item.isActiveRank = true
      }
      return item
    })
  }, [searchDataJoin])

  const description = get(campDetail, 'description', '')

  const type = get(campDetail, 'type')
  const reward = get(campDetail, 'reward')
  const isUpTo = get(campDetail, 'isUpTo')
  const renderTotalReward = isUpTo
    ? `Up To ${formatPrice(reward, false, true)}`
    : `${formatPrice(reward, false, true)} ${tokenSymbol}`
  const listSocial = get(campDetail, 'social')
  const name = get(campDetail, 'name')
  const owner = get(campDetail, 'owner')
  const logo = get(campDetail, 'logo')
  const campImage = get(campDetail, 'image')

  const startTimeStamp = get(campDetail, 'start')
  const endTimeStamp = get(campDetail, 'end')

  const toDay = new Date().getTime()
  const isGoing = startTimeStamp < toDay && endTimeStamp > toDay
  const isEnd = endTimeStamp <= toDay
  const status = isGoing ? 'onGoing' : isEnd ? 'completed' : 'upComing'

  const badgeStatus = useMemo(() => {
    if (status === 'onGoing') {
      return 'success'
    }
    if (status === 'completed') {
      return 'danger'
    }
    if (status === 'upComing') {
      return 'warning'
    }
    return 'success'
  }, [status])

  return (
    <div className={cn(styles.snapshotDetailContainer, 'container')}>
      <div
        className="detail-banner box-wrapper"
        // style={{ backgroundImage: `url(${images.waveBanner})` }}
      >
        <div className="d-flex align-items-center">
          <figure className="coin-image">
            <TokenImage src={logo} className="" alt="" />
          </figure>
          <div className="coin-info-text">
            <div className="color-grey text-sm">{owner}</div>
            <div className="text-xl bold">{name}</div>

          </div>
          <Badge className="ml-2" variant={badgeStatus}>
            {t(status)}
          </Badge>
        </div>

        <div className="detail-info">
          {/* <div>
            <div className="detail-info__main-text bold uppercase">
              {renderTotalReward}
            </div>
            <div className="detail-info__text  color-grey">
              {t('totalReward')}
            </div>
          </div> */}

          {/* <div className="ml-5"> */}
          <div>
            <div className="detail-info__main-text bold">
              {moment(startTimeStamp).format(isMobile ? 'DD/MM' : 'DD MMM')} -{' '}
              {moment(endTimeStamp).format(
                isMobile ? 'DD/MM/YYYY' : 'DD MMM YYYY'
              )}
            </div>
            <div className="detail-info__text  color-grey">{t('duration')}</div>
          </div>
        </div>

        <div
          className="text-sm color-grey mt-4 description"
          dangerouslySetInnerHTML={{
            __html: description.replaceAll('\n', '<br/>')
          }}
        ></div>

        <div className="mt-4 d-flex align-items-center">
          <SocialNetworkLink listSocial={listSocial} />
          <Tooltip content={t('share')}>
            <Icon
              name="app_share"
              onClick={onShowShareModal}
              backgroundClassName="icon-share-container"
            />
          </Tooltip>
        </div>

        <div onClick={onZoomImage}>
          <BannerImage
            className="banner-image cursor-pointer"
            src={campImage}
          />
        </div>

        <div className="bottom-image">
          <img src={images.sarosAtom} alt="" />
        </div>
      </div>

      <div className="mt-4 box-wrapper">
        <div className="d-flex justify-content-between align-items-center">
          <InputSearch
            handleClear={handleClearSearch}
            className="input-search"
            initValue={txtSearch}
            handleChange={handleSearchSnapshotDetail}
            placeHolder={t('searchByAddress')}
          />
          <PageDropdown
            defaultValue={itemDropdown}
            className="right-toggle-down"
            menuClassName="right-menu-down"
            options={ARR_SORT}
            onChange={onChangeSort}
          />
        </div>

        <CustomTable
          isLoading={isLoading}
          className="mt-4"
          columns={tableHead}
          dataSource={tableData}
        />
        <div
          className={cn(
            getLength(tableData) > 0
              ? 'd-flex justify-content-center'
              : 'd-none'
          )}
        >
          <Pagination totalPage={total} handleSelectedPage={onChangePage} />
        </div>
      </div>
    </div>
  )
}

export default LeaderBoard
